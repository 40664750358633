import React, { useEffect } from "react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Container } from "@material-ui/core"
import FirebaseAuth from 'components/CustomFirebaseAuth/CustomFirebaseAuth'
import image from "assets/img/bg11.jpg";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/nextjs-material-kit/pages/loginPage.js";
import dynamic from 'next/dynamic'
import { withRouter } from 'next/router'

const useStyles = makeStyles(styles);

const HeaderLinks = dynamic(() => import("components/Header/HeaderLinks.js"))
const Header = dynamic(() => import("components/Header/Header.js"))

function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 10);
  const classes = useStyles();
  const { domainInfo, ...rest } = props;

  var backgroundImage = domainInfo?.bgLogin ? domainInfo.bgLogin : require("assets/img/bg11.jpg")

  useEffect(() => {
    if(props?.router?.query?.r == '1') {
        window.location = window.location.href.replace('?r=1', '');
        setTimeout(() => {
          window.location.reload();
        }, 250);
      }
  }, [props?.router?.query?.r])

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="My Love - Fitness"
        rightLinks={<HeaderLinks {...props}/>}
        {...rest}
        {...props}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundSize: "cover",
          backgroundPosition: "bottom center"
        }}
      >
        <div className={classes.container}>
          <Container justify="center">
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Register / Login using buttons below</h4>
                  </CardHeader>
                  <CardBody>
                    <FirebaseAuth />
                  </CardBody>
                </form>
              </Card>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default withRouter(LoginPage)